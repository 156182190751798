import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/src/components/post-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`mdx page 2`}</h1>
    <h2>{`mdx example`}</h2>
    <ul>
      <li parentName="ul">
        <ol parentName="li">
          <li parentName="ol">{`[MDX]`}{`(`}</li>
        </ol>
      </li>
      <li parentName="ul">
        <ol parentName="li" {...{
          "start": 2
        }}>
          <li parentName="ol">{`[MDX]`}{`(`}</li>
        </ol>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      